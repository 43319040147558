.online {
  background-color: #FFE275;
  padding: 10px 15px 0 15px;
  border-radius: 20px;
  margin-top: 20px;

  img {
    display: block;
    margin-left: auto;
    margin-right: 25px;
  }
}

.online__title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin: 10px 0;
}

.online__text {
  font-size: 14px;
  line-height: 21px;
  margin: 10px 0;
  a {
    font-weight: 700;
    text-decoration: underline;
  }
}