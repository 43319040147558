$headerBg: #1CCAD1;

.header {
  background-color: $headerBg;
  box-shadow: 0px 0px 15px rgba(64, 27, 27, 0.25);
  position: relative;
}

.header__wrap {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  a {
    color: $white;
    display: flex;
    align-items: center;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }
  span {
    margin-left: 15px;
  }
}

.header__dates {
  list-style: none;
  display: flex;
  margin: 0 20px;
  padding: 0;
  flex-grow: 1;
}

.header__date {
  flex-basis: 195px;
  font-size: 14px;
  line-height: 17px;
  padding-right: 10px;
  p {
    margin: 0;
    color: $white;
    &:first-child {
      color: $yellow;
      font-weight: 600;
      margin-bottom: 3px;
    }
  }
  span {
    width: 27px;
    background-color: $white;
    border-radius: 50%;
    font-size: 16px;
    line-height: 21px;
    padding: 3px 0;
    color: $red;
    font-weight: 700;
    display: inline-block;
    text-align: center;
  }
}

.header__auth {
  flex-basis: 120px;
  > div {
    font-size: 20px;
    line-height: 24px;
    color: $white;
    font-weight: 600;
    background: linear-gradient(180deg, #31B9ED 0%, #557AD0 100%);
    border-radius: 23px;
    text-align: center;
    cursor: pointer;
    padding: 12px 0;
    &:hover {
      background: linear-gradient(180deg, darken(#31B9ED, 10%) 0%, darken(#557AD0, 10%) 100%);
    }
  }
}

@media (max-width: 1024px) {
  .header__wrap {
    flex-wrap: wrap;
  }

  .header__dates {
    order: 3;
    margin: 10px 0;
    justify-content: space-between;
    flex-basis: 100%;
  }

  .header__date {
    padding-right: 0;
    flex-basis: 195px;
  }
}

@media (max-width: 580px) {
  .header__dates {
    flex-wrap: wrap;
  }
  .header__date {
    flex-basis: 49%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .header__logo {
    flex-basis: 100%;
    margin-bottom: 10px;

    a {
      justify-content: center;
    }
  }

  .header__auth {
    flex-basis: 200px;
    margin: 0 auto;
  }
}