.documents {
  background: linear-gradient(180deg, #02C4D7 0%, #5BD9C2 100%);
}

.documents__wrap {
  padding-top: 10px;
  padding-bottom: 10px;
}

.documents__title {
  font-size: 35px;
  line-height: 43px;
  color: $white;
  margin: 20px 0;
  text-align: center;
}

.documents__block {
  display: flex;
  justify-content: space-around;
  background: url(#{$path-img}/border.svg) left center no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  &::after {
    background: url(#{$path-img}/boy-doc.png) left center no-repeat;
    width: 141px;
    height: 235px;
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    bottom: 100px;
    transform: translateX(-50%);
  }
}

.documents__item {
  text-align: center;
  flex-basis: 50%;
}

.documents__name {
  font-size: 33px;
  line-height: 40px;
  font-weight: 700;
  color: $white;
  span {
    background: url(#{$path-img}/line-yellow.svg) left bottom / 100% auto no-repeat;
    padding-bottom: 10px;
  }
}

.documents__txt {
  font-size: 20px;
  line-height: 24px;
  color: $white;
  font-weight: 700;
}

.documents__btn {
  width: 270px;
  background: linear-gradient(180deg, #F6B214 0%, #FD7643 100%);
  box-sizing: border-box;
  box-shadow: 0px 0px 20px $white;
  border-radius: 67px;
  display: block;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
  color: $white;
  margin: 25px auto 40px;
  border: 4px solid #FFF49A;
  padding: 20px 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: linear-gradient(180deg, darken(#F6B214, 10%) 0%, darken(#FD7643, 10%) 100%);
    color: $white;
    text-decoration: none;
  }
}

@media (max-width: 1200px) {
  .documents__block {
    background: none;
    padding: 0;
    &::after {
      display: none;
    }
  }
  .documents__name {
    margin-top: 20px;
  }
}

@media (max-width: 860px) {
  .documents__block {
    flex-wrap: wrap;
  }
  .documents__item {
    flex-basis: 100%;
  }
}

@media (max-width: 768px) {
  .documents__name {
    font-size: 22px;
    line-height: 26px;
  }

  .documents__title {
    font-size: 26px;
    line-height: 34px;
  }

  .documents__txt {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
}