.about {
  background: $lightBlueGradient;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: flex-start;
}

.about__title {
  position: relative;
  display: inline-block;
  background-color: $white;
  color: $blue;
  border-radius: 50px;
  margin-left: 10px;
  margin-bottom: 0;
  padding-right: 60px;
  box-sizing: border-box;
  span {
    width: 205px;
    display: block;
    font-size: 24px;
    line-height: 29px;
    font-weight: 900;
    padding: 10px 0 10px 20px;
    box-sizing: border-box;
  }
  &:nth-of-type(1) {
    background-image: url('#{$path-img}teacher.svg');
    background-position: calc(100% - 20px) bottom;
    background-repeat: no-repeat;
  }
  &:nth-of-type(2) {
    background-image: url('#{$path-img}pupil.svg');
    background-position: calc(100% - 20px) bottom;
    background-repeat: no-repeat;
  }
}

.about__element {
  margin: 10px 35px;
  padding-left: 63px;
  padding-top: 7px;
  padding-bottom: 7px;
  p {
    font-size: 18px;
    line-height: 22px;
    color: $white;
    font-weight: 700;
    margin: 0;
  }
  span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    display: block;
  }
  &:nth-of-type(1) {
    background: url('#{$path-img}about.svg') left 7px no-repeat;
  }
  &:nth-of-type(2) {
    background: url('#{$path-img}about2.svg') left 7px no-repeat;
  }
  &:nth-of-type(3) {
    background: url('#{$path-img}about3.svg') left 7px no-repeat;
  }
  &:nth-of-type(4) {
    background: url('#{$path-img}about4.svg') left center no-repeat;
  }
  &:nth-of-type(5) {
    background: url('#{$path-img}about5.svg') left 15px no-repeat;
  }
  &:nth-of-type(6) {
    background: url('#{$path-img}about6.svg') left center no-repeat;
  }
}

.about__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about__subtitle {
  font-size: 30px;
  line-height: 37px;
  font-weight: 700;
  margin: 0 0 15px 0;
}

.about__text {
  margin: 0 20px 20px 0;
  flex-basis: 375px;
  flex-grow: 1;
  p {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }
}

.about__right {
  p {
    font-size: 16px;
    line-height: 26px;
    margin: 15px 0;
  }
}

.about__advantages {
  margin: 30px 0;
}

.about__btn {
  border-top: 5px solid $white;
  a {
    width: 270px;
    background: linear-gradient(180deg, #F6B214 0%, #FD7643 100%);
    box-sizing: border-box;
    box-shadow: 0px 0px 20px $white;
    border-radius: 67px;
    display: block;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    font-weight: 700;
    color: $white;
    margin: 25px auto 20px;
    border: 4px solid #FFF49A;
    padding: 20px 0;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: linear-gradient(180deg, darken(#F6B214, 10%) 0%, darken(#FD7643, 10%) 100%);
    }
  }
}

@media (max-width: 980px) {
  .about__wrap {
    flex-direction: column;
  }
  .about__left {
    flex-basis: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .about__right {
    flex-basis: auto;
  }
}

@media (max-width: 580px) {
  .about__block {
    flex-direction: column;
  }
  .about__text {
    margin-right: 0;
    margin-bottom: 0;
    flex-basis: auto;
  }
}

@media (max-width: 480px) {
  .about__element {
    margin-left: 15px;
  }
  .about__title {
    span {
      padding-left: 15px;
    }
  }
}