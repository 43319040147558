footer.footer {
  background: #02C4D7;
}

div.footer__wrap {
  display: flex;
  align-items: center;
  color: $white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer__left {
  flex-basis: 290px;
}

.footer__right {
  flex-basis: calc(100% - 290px);
}

.footer__project {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  a {
    color: #7DB1FF;
  }
}

.footer__organizer {
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
}

.footer__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    flex-basis: 49%;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0.8% 5px 0;
  }
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  .footer__wrap {
    flex-direction: column;
  }
  div.footer__wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .footer__left {
    flex-basis: auto;
    margin-bottom: 20px;
  }
  .footer__right {
    flex-basis: auto;
  }
}