.key_order {
  background: linear-gradient(356.75deg, #FFF4C8 5.58%, #FFE375 86.24%);
  margin-bottom: 0;
  margin-top: 10px;
  border: 3px solid #EEBF6D;
}

.key_order__pay_summ {
  border: none;
  background: $white;
  &::before {
    display: none;
  }
  &:after {
    display: none;
  }
}

.key_order__btn_wr {
  background: none;
}

.key_order__btn {
  background: linear-gradient(180deg, #F3CC00 -27.78%, #FF6C4B 98.63%);
  box-shadow: 0px 10px 20px #F48B22;
  border: none;
  &:hover {
    background: linear-gradient(180deg, darken(#F3CC00, 10%), darken(#FF6C4B, 10%));
  }
  &::before {
    display: none;
  }
}

.key_order__msg {
  background: none;
  padding: 0;
  p {
    color: $black;
    margin: 0;
  }
  b {
    color: $black;
  }
  a {
    text-decoration: underline;
  }
}

@media (max-width: 1200px) {
  .key_order {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) and (min-width: 200px) {
  .key_order__discipline_title, .key_order__num_name, .key_order__pay_title {
    font-size: 20px;
    line-height: 26px;
  }
  .key_order__btn {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 0;
  }
}
