.first-screen {
  background:
         url(#{$path-img}/icons-left.svg) 50px 50px no-repeat,
         url(#{$path-img}/icons-right.svg) calc(100% - 50px) 50px no-repeat,
         url(#{$path-img}/bg-header.svg) center -1px / cover no-repeat;
}

.first-screen__wrap {
  padding-top: 10px;
}

.first-screen__title {
  margin: 10px auto;
  background: linear-gradient(180deg, #FF97B0 0%, #FF9B25 100%);
  border-radius: 23px;
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  color: $white;
  font-weight: 700;
  padding: 10px 15px 12px;
  max-width: 940px;
  span {
    font-size: 33px;
    line-height: 40px;
  }
}

.first-screen__block {
  display: flex;
  align-items: center;
}

.first-screen__img {
  flex-basis: 40%;
  margin-right: 10px;
  img {
    display: block;
  }
}

.first-screen__list {
  padding: 0;
  list-style: none;
  display: flex;
}

.first-screen__item {
  background: linear-gradient(180deg, #FEAD15 0%, #fff 100%);
  flex-basis: 280px;
  border-radius: 10px;
  text-align: center;
  padding: 3px;
  color: $white;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  > div {
    background: linear-gradient(180deg, #FF5E85 0%, #41B7EC 100%);
    padding: 10px;
    border-radius: 8px;
    height: 100%;
  }

  p {
    margin: 10px 0;
  }

  span {
    color: $yellow;
  }

  &:first-child {
    margin-right: 15px;
  }

  &:last-child {
    > div {
      background: url(#{$path-img}/childs.svg) center bottom no-repeat,
      linear-gradient(180deg, #A2ABF8 0%, #F5628A 100%);
      padding-bottom: 55px;
    }
  }
}

.first-screen__price {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 64px;
    line-height: 64px;
    color: $yellow;
    font-weight: 700;
}

.first-screen__information {
  flex-basis: 60%;
}

.first-screen__list {
  display: flex;
}

.first-screen__text {
  color: $blue;
  margin-top: 40px;
  max-width: 560px;

  p {
    margin: 0;
  }
}

.first-screen__subtitle {
  font-size: 25px;
  line-height: 32px;
  font-weight: 700;
  background: url(#{$path-img}/line.svg) left bottom no-repeat;
  padding-bottom: 10px;
}

.first-screen__txt {
  font-size: 22px;
  line-height: 31px;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .first-screen {
    background: linear-gradient(180deg, #3bd1ca, #4ed6c5);
  }

  .first-screen__list {
    margin-top: 0;
  }

  .first-screen__wrap {
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .first-screen__img {
    display: none;
  }

  .first-screen__information {
    flex-basis: 100%;
  }

  .first-screen__list {
    margin-top: 20px;
    justify-content: center;
  }

  .first-screen__text {
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .first-screen__txt {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (max-width: 480px) {
  .first-screen__list {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .first-screen__item {
    margin: 10px 0;
    &:first-child {
      margin-right: 0;
    }
  }
}