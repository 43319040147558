div.registration {
  margin-bottom: 0;
  float: none;
  ul.registration__menu {
    top: 44px !important;
    right: 0 !important;
    left: auto !important;
    .mi_header__name {
      color: $black;
    }
  }
  label {
    display: block;
  }
}

.registration__link, .registration__cabinet {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: $white;
  padding: 12px 20px;
  cursor: pointer;
  background: linear-gradient(180deg, #31B9ED 0%, #557AD0 100%);
  border-radius: 100px;
  &:hover {
    background: linear-gradient(180deg, darken(#31B9ED, 10%) 0%, darken(#557AD0, 10%) 100%);
    text-decoration: none;
    color: $white;
  }
}