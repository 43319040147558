$white: #fff;
$black: #000;
$yellow: #FFE400;
$red: #FF4B68;
$blue: #001484;
$beige: #FFF4C8;

$lightBlueGradient: linear-gradient(180deg, #53D7C4 0%, #02C4D7 100%);

$path-img: '../img/';

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap&subset=cyrillic');

@import "s3/header";
@import "s3/first-screen";
@import "s3/key-order";
@import "s3/content";
@import "s3/about";
@import "s3/advantages";
@import "s3/online";
@import "s3/documents";
@import "s3/registration";
@import "s3/footer";
@import "s3/spoiler";

html {
  font-family: 'Montserrat', sans-serif;
}

.wrap {
  max-width: 1162px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.repeat-bg {
  background: url(#{$path-img}/bg-icons.png) left top repeat;
}

@media (max-width: 1200px) {
  .repeat-bg {
    background: none;
  }
}

@media (max-width: 991px) {
  .static {
    position: static !important;
    .sidebar-menu {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  .pt-200 {
    padding-top: 200px;
  }
}

@media (max-width: 1241px) {
  .key_orders_page {
    .static {
      position: static !important;
      .sidebar-menu {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
    .pt-200 {
      padding-top: 200px;
    }
  }
}

@media (min-width: 320px) {
  .sidebar-menu {
    box-sizing: border-box;
    border-radius: 16px;
    margin: 30px 0 20px 0;
    ul.a-admin {
      padding: 0 10px;
      border: 1px solid #00b5f4;
      margin-top: 0;
      border-radius: 16px;
    }
    ul.a-admin li {
      font-size: 14px;
      list-style-type: none;
      margin-left: 10px;
      margin-right: 10px;
      clear: both;
      font-family: "Open Sans", serif;
      a {
        color: #3194AE;
        span {
          display: inline-block;
          width: 22px;
          padding: 3px 6px 3px 6px;
          text-align: center;
          color: #fff;
          border-radius: 20px;
          background: #ffea00;
          background: linear-gradient(to bottom, #ffea00 0%, #ffb500 100%);
        }
        &:hover {
          color: #4598AC;
          text-decoration: none;
        }
      }
      &.dr-admin-circle {
        line-height: 16px;
      }
      &.a-nou {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
        a {
          color: #555;
        }
      }
    }
    .mi_header__name {
      font-weight: bold;
      box-sizing: border-box;
      margin: -10px -21px 0;
      padding: 10px 20px 10px 24px !important;
      border-radius: 20px 20px 0 0 !important;
    }

  }

  .kr-black-white-icons {
    background-size: auto;
    width: auto;
    height: auto;
  }
  .kr-black-white-icons.kr-star {
    background-position: -454px 1px
  }

  .kr-black-white-icons.kr-archive {
    background-position: -129px -227px
  }

  .kr-black-white-icons.kr-profile {
    background-position: -434px 1px
  }

  .sidebar-menu ul.a-admin.kr-main-menu li {
    padding: 4px 0;
    transition: ease-out 0.3s;
  }
  .sidebar-menu ul.a-admin.kr-main-menu li > i {
    margin: 0 2px 0 0;
  }
}

.dr-sort-m.dr-sort-orders {
  a {
    color: #0A7FBB;
  }
}

.dr-pupil-row .dr-red {
  top: 0;
}

.a-del-x {
  line-height: 13px;
}

.a-sort-m .a-escho {
  background: linear-gradient(to right, #ff7800 0%, #ffb300 100%);
  padding: 10px;
  border: none;
  &::before, &::after {
    display: none;
  }
  &:hover {
    background: linear-gradient(to right, #ff8c14 0%, #ffc714 100%);
    border: none;
  }
}

.kr-pay-btn {
  background: linear-gradient(to bottom,rgba(23,221,61,.9) 0%,#24B734 100%);
  border: none;
  &::after {
    display: none;
  }
  &:hover {
    background: linear-gradient(to bottom, darken(rgba(23,221,61,.9), 10%) 0%, darken(#24B734, 10%) 100%);

  }
}

.a-alert-block {
  border: none;
  margin: 0 0 20px 0;
  padding: 0;
}

.application-completed {
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
}