.advantages {
  background-color: $beige;
  border-radius: 20px;
  padding: 28px 10px;
  position: relative;
}

.advantages__item {
  background: linear-gradient(180deg, #FFFFFF 0%, #FFBE54 100%);
  box-shadow: 0px 3px 10px #3B92E2;
  border-radius: 12px;
  width: 132px;
  text-align: center;
  padding: 10px 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 260px;
  position: relative;
  top: 0;
  &::after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #FFBE54;
    content: '';
  }
}

.advantages__text {
  font-size: 13px;
  line-height: 16px;
}

.advantages__top {
  display: flex;
  justify-content: space-between;
  .advantages__item {
    margin: 30px 0 0;
    &:nth-child(1) {
      left: 6.9%;
    }
    &:nth-child(2) {
      left: -0.4%;
    }
    &:nth-child(3) {
      right: 7.2%;
    }
  }
}

.advantages__bottom {
  display: flex;
  justify-content: center;

  .advantages__item {
    margin: 0 0 30px;
    &::after {
      top: -20px;
      transform: translateX(-50%) rotate(180deg);
    }
    &:nth-child(1) {
      right: 8%;
    }
    &:nth-child(2) {
      left: 7.1%;
    }
  }
}

@media (max-width: 1200px) {
  .advantages {
    padding: 5px 20px;
  }

  .advantages__line {
    display: none;
  }

  .advantages__top, .advantages__bottom {
    margin: 10px 0;
    .advantages__item {
      min-height: auto;
      flex-basis: 30%;
      margin: 20px 0;
      &::after {
        display: none;
      }
      &:nth-child(1) {
        left: auto;
        right: auto;
      }
      &:nth-child(2) {
        left: auto;
        right: auto;
      }
      &:nth-child(3) {
        left: auto;
        right: auto;
      }
    }
  }

  .advantages__bottom {
    .advantages__item {
      margin: 20px;
    }
  }
}

@media (max-width: 560px) {

  .advantages__top, .advantages__bottom {
    flex-wrap: wrap;
    .advantages__item {
      flex-basis: 100%;
      margin: 10px 0;
    }
  }

}