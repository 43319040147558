.content {
  position: relative;
  &::after {
    background: url(#{$path-img}/udder.svg) center bottom / cover no-repeat;
    content: '';
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 430px;
  }
}

.content__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content__wrap {
  display: flex;
  padding-top: 30px;
  padding-bottom: 100px;
  background-color: $white;
}

.content__left {
  flex-basis: calc(100% - 364px);
  margin-right: 20px;
  position: relative;
  z-index: 1;
}

.content__right {
  flex-basis: 364px;
  position: relative;
  z-index: 1;
}

.content__txt {
  flex-basis: calc(100% - 300px);
}

.content__img {
  flex-basis: 250px;
}

.content__title {
  font-size: 35px;
  line-height: 43px;
  font-weight: 700;
  margin-top: 0;
}

.content__text {
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 980px) {
  .content {
    &::after {
      display: none;
    }
  }
  .content__wrap {
    padding-bottom: 40px;
  }
}

@media (max-width: 860px) {
  .content__wrap {
    flex-wrap: wrap;
  }

  .content__left {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .content__right {
    flex-basis: 100%;
  }
}

@media (max-width: 768px) {
  .content__txt {
    flex-basis: 100%;
  }
  .content__img {
    display: none;
  }

  @media (max-width: 768px) {
    .content__title {
      font-size: 26px;
      line-height: 34px;
    }
  }

}